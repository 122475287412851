import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _7540d161 = () => interopDefault(import('../pages/About.vue' /* webpackChunkName: "pages/About" */))
const _5b9f4a8b = () => interopDefault(import('../pages/Banks.vue' /* webpackChunkName: "pages/Banks" */))
const _01e81138 = () => interopDefault(import('../pages/Buyout.vue' /* webpackChunkName: "pages/Buyout" */))
const _32b6fe2f = () => interopDefault(import('../pages/Contacts.vue' /* webpackChunkName: "pages/Contacts" */))
const _fbeece80 = () => interopDefault(import('../pages/Cookie.vue' /* webpackChunkName: "pages/Cookie" */))
const _832d8cd0 = () => interopDefault(import('../pages/Credit/index.vue' /* webpackChunkName: "pages/Credit/index" */))
const _3ab7c3ff = () => interopDefault(import('../pages/Exchange.vue' /* webpackChunkName: "pages/Exchange" */))
const _cd6935aa = () => interopDefault(import('../pages/Favorites.vue' /* webpackChunkName: "pages/Favorites" */))
const _2220e910 = () => interopDefault(import('../pages/Installment/index.vue' /* webpackChunkName: "pages/Installment/index" */))
const _fc0a4648 = () => interopDefault(import('../pages/Privacy.vue' /* webpackChunkName: "pages/Privacy" */))
const _2a995ab2 = () => interopDefault(import('../pages/Reviews1111111.vue' /* webpackChunkName: "pages/Reviews1111111" */))
const _bcc96ab6 = () => interopDefault(import('../pages/Thanks.vue' /* webpackChunkName: "pages/Thanks" */))
const _1c7b3d24 = () => interopDefault(import('../pages/Credit/_bank/index.vue' /* webpackChunkName: "pages/Credit/_bank/index" */))
const _1d868c46 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _4e880c14 = () => interopDefault(import('../pages/_category/index.vue' /* webpackChunkName: "pages/_category/index" */))
const _24f0fc1e = () => interopDefault(import('../pages/_category/_mark/index.vue' /* webpackChunkName: "pages/_category/_mark/index" */))
const _6da6f944 = () => interopDefault(import('../pages/_category/_mark/_model/index.vue' /* webpackChunkName: "pages/_category/_mark/_model/index" */))
const _6b04977c = () => interopDefault(import('../pages/_category/_mark/_model/_car/index.vue' /* webpackChunkName: "pages/_category/_mark/_model/_car/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'active',
  linkExactActiveClass: 'active',
  scrollBehavior,

  routes: [{
    path: "/About",
    component: _7540d161,
    name: "About"
  }, {
    path: "/Banks",
    component: _5b9f4a8b,
    name: "Banks"
  }, {
    path: "/Buyout",
    component: _01e81138,
    name: "Buyout"
  }, {
    path: "/Contacts",
    component: _32b6fe2f,
    name: "Contacts"
  }, {
    path: "/Cookie",
    component: _fbeece80,
    name: "Cookie"
  }, {
    path: "/Credit",
    component: _832d8cd0,
    name: "Credit"
  }, {
    path: "/Exchange",
    component: _3ab7c3ff,
    name: "Exchange"
  }, {
    path: "/Favorites",
    component: _cd6935aa,
    name: "Favorites"
  }, {
    path: "/Installment",
    component: _2220e910,
    name: "Installment"
  }, {
    path: "/Privacy",
    component: _fc0a4648,
    name: "Privacy"
  }, {
    path: "/Reviews1111111",
    component: _2a995ab2,
    name: "Reviews1111111"
  }, {
    path: "/Thanks",
    component: _bcc96ab6,
    name: "Thanks"
  }, {
    path: "/Credit/:bank",
    component: _1c7b3d24,
    name: "Credit-bank"
  }, {
    path: "/",
    component: _1d868c46,
    name: "index"
  }, {
    path: "/:category",
    component: _4e880c14,
    name: "category"
  }, {
    path: "/:category/:mark",
    component: _24f0fc1e,
    name: "category-mark"
  }, {
    path: "/:category/:mark/:model",
    component: _6da6f944,
    name: "category-mark-model"
  }, {
    path: "/:category/:mark/:model/:car",
    component: _6b04977c,
    name: "category-mark-model-car"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
